<template>
  <v-container class="app-page">
    <v-row>
      <v-col>
        <v-row v-if="loading">
          <v-col class="pa-1" v-for="i in 5" :key="'skeleton-col' + '-' + i" cols="12">
            <v-card class="pa-5">
              <v-skeleton-loader
                :ref="'skeleton-' + i"
                :boilerplate="false"
                type="paragraph"
                :tile="false"
                class="mx-auto"
              ></v-skeleton-loader>
            </v-card>
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col>
            <v-card class="list-item">
              <img class="card-icon" src="@/assets/empresa/escanear.svg" />
              <div class="list-content">
                <div class="title">Escanear QR</div>
              </div>
            </v-card>
            <router-link :to="{ name: 'empresa_configuracion_manual' }">
              <v-card class="list-item">
                <img class="card-icon" src="@/assets/empresa/configuracion.svg" />
                <div class="list-content">
                  <div class="title">Configuración Manual</div>
                </div>
              </v-card>
            </router-link>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="text-center">
        <p class="mensaje">Un vez agregada podrás ver todos los recibos de sueldo desde tu teléfono.</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "nueva",
  data() {
    return {
      loading: false,
    };
  },

  created() {},

  watch: {},

  computed: {},

   mounted() {
    this.$store.commit("setNavigationDrawer", false)
    this.$store.commit("setBackButton", false);
    this.$store.commit("setToolbarTitle", "Nueva Empresa");
  },

  methods: {},
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.mensaje {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 113%;
  text-align: center;
  color: rgba(67, 67, 67, 0.4);
  margin: 0 auto;
}
</style>